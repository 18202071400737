<script>
import { required } from 'vuelidate/lib/validators'
export default {
  name: 'ImageCardType',
  props: {
    card: {
      type: Object,
      required
    },
    defaultImage: {
      type: String
    }
  },
  computed: {
    cardType () {
      return (this.card.contentType && this.card.contentType.alias) || this.card.type
    }
  }
}
</script>
<template>
  <v-card class="image-card-type--container">
    <v-img height="170px" :src="card.coverImageURL || defaultImage"></v-img>
    <div class="image-card-type--body">
      <div class="image-card-type--type h7">
        <v-icon class="mr-2">{{ iconsMap[cardType] }}</v-icon>
        {{ $t(`content.type:${cardType}`) }}
      </div>
      <h5 class="h7">{{ card.title }}</h5>
    </div>
  </v-card>
</template>
<style lang="scss">
.image-card-type--container.v-card {
  border-radius: 10px;
  margin: 24px 0;
  background-color: $neutral-lightest;
  .image-card-type--body {
    padding: 17px 20px 20px;
    .image-card-type--type {
      display: flex;
      align-items: center;
      width: 100%;
      color: $primary-medium;
      font-family: $lato;
      font-weight: bold;
      font-size: $font-size-normal;
      text-align: left;
      .v-icon {
        color: $primary-medium
      }
    }
    h5.h7 {
      margin-top: 8px;
      text-align: left;
    }
  }
}
</style>

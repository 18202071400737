<script>
import InputField from '@/components/general/InputField'
export default {
  name: 'ShareModal',
  components: { InputField },
  data () {
    return {
      show: false,
      copySuccess: false,
      showTooltip: false
    }
  },
  props: {
    value: {
      type: Boolean
    },
    details: {
      type: Object
    }
  },
  watch: {
    value: {
      immediate: true,
      handler () {
        this.show = this.value
      }
    }
  },
  methods: {
    close () {
      this.$emit('close')
    },
    showTip () {
      this.showTooltip = true
      setTimeout(() => {
        this.showTooltip = false
      }, 3000)
    },
    onCopy () {
      this.copySuccess = true
    },
    onError () {
      this.copySuccess = false
    }
  }
}
</script>
<template>
  <v-dialog v-model="show" width="600px" @click:outside="close">
    <v-card class="modal-share-link--container">
      <div class="modal-share-link--header">
        <h5 class="h7 text-left modal-share-link--title">
          {{ $t('modal.share.link:title') }}
        </h5>
        <span class="top-right"><v-btn icon @click="close" color="#1200D3"><v-icon>mdi-close</v-icon></v-btn></span>
      </div>
      <div class="modal-share-link--body">
        <slot></slot>
      </div>
      <div class="modal-share-link--share-container">
        <input-field
          ref="linkInput"
          class="modal-share-link--text-field"
          content-class="v-input--is-focused"
          small
          readonly
          outlined
          buttonHeight
          hide-validation
          :value="details.link"
        />
        <v-tooltip v-model="showTooltip" top open-on-click :color="copySuccess ? '#16AC99' : '#D52641'">
          <template v-slot:activator="{ attrs }">
            <v-btn
              v-bind="attrs"
              dark
              class="copy-link--button bold"
              color="#1200D3"
              @click="showTip"
              v-clipboard:copy="details.link"
              v-clipboard:success="onCopy"
              v-clipboard:error="onError"
            >
              <v-icon>mdi-content-copy</v-icon>
              {{ $t('modal.share.link:button') }}
            </v-btn>
          </template>
          <span>{{ $t(`modal.share.link:${copySuccess ? 'success' : 'error'}`) }}</span>
        </v-tooltip>
      </div>
    </v-card>
  </v-dialog>
</template>
<style lang="scss">
.modal-share-link--container {
  .top-right .v-icon.mdi {
    color: $primary-medium;
  }
  padding: 20px;
  .modal-share-link--body {
    min-height: 50px;
  }
  .modal-share-link--share-container {
    display: flex;
    width: 100%;
    .modal-share-link--text-field {
      flex: 1;
    }
    .copy-link--button {
      height: 44px;
      margin-left: 8px;
      .v-icon {
        margin-right: 8px;
      }
    }
  }
}
</style>
